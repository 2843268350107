import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SafePipe } from 'src/app/exports/pipes/safepipe';
import * as SvgPanZoom from 'svg-pan-zoom';

declare var $: any;

@Component({
  selector: '[data-app-scheme-block]',
  templateUrl: './scheme-block.component.html',
  styleUrls: ['./scheme-block.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SchemeBlockComponent implements OnInit, AfterViewInit {

  @Input() html: string = '';
  @Input() identical: string = '';
  @Output() update = new EventEmitter();
  currentZoomStep: number;
  currentZoom: number;

  constructor(
    private dom$: DomSanitizer,
    private safe$: SafePipe
  ) { }

  ngOnInit(): void {
    if (this.html && this.identical) {
      this.sceneFunction();
    }
  }

  ngAfterViewInit() {
    if (!this.isTouchDevice()) {
      const svgElement = document.querySelector('.scene__image svg') as SVGElement;
      const panZoomTiger = SvgPanZoom(svgElement);
    }

  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth < 768) {
      this.currentZoomStep = 1;
      this.currentZoom = 5;
    } else {
      this.currentZoomStep = 0.2;
      this.currentZoom = 2;
    }
  }

  sceneFunction() {
    let zoom = 1;
    $(document).on("click", `#${this.identical} .size__item`, (el: any) => {
      this.onResize();
      if ($(el.target).hasClass("size__item_plus") && zoom < this.currentZoom) zoom += this.currentZoomStep;
      if ($(el.target).hasClass("size__item_minus") && zoom > 1) zoom -= this.currentZoomStep;
      $(`#${this.identical} .scene__image`).find("svg").css("transform", "scale(" + zoom + ")");
    });
  }

  resetOpacity() {
    $('[data-category-id]').css('opacity', 1);
    $('[data-price-id]').css('opacity', 1);
  }

  safe() {
    this.update.emit(true);
    return this.safe$.transform(this.html);
  }

  private isTouchDevice(): boolean {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.maxTouchPoints > 0;
  }

}
