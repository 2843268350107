<div class="checkout-layout">
  <main class="layout__main">
    <!-- СЕКЦИЯ ОФОРМЛЕНИЯ ЗАКАЗА -->
    <div class="layout__section section">
      <div class="section__header">
        <div class="buy__header">
          <div class="wysiwyg">
            <h3>Оформление заказа</h3>
          </div>
          <div>
            <a class="nav__link" routerLink="/basket">Назад к корзине</a>
          </div>
        </div>
      </div>
      <div data-app-checkout-wrap></div>
    </div>
  </main>
  <div *ngIf="event" data-app-footer [event]="event"></div>
</div>
