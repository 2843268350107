import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IEventItem } from 'src/app/exports/interfaces/IEventItem';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.css']
})
export class BasketComponent implements OnInit {
  event: IEventItem;

  constructor(
    private event$: EventService
  ) {
    this.event = this.event$.getEventData();
  }

  ngOnInit(): void {
  }

}
