import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectModule } from 'src/app/components/select/select.module';
import { SchemeBlockModule } from 'src/app/components/global/scheme-block/scheme-block.module';
import { CounterModule } from 'src/app/components/counter/counter.module';
import { LoaderModule } from 'src/app/components/global/loader/loader.module';
import { InputModule } from 'src/app/components/input/input.module';
import { BuyBlockComponent } from './buy-block.component';


@NgModule({
  declarations: [
    BuyBlockComponent
  ],
  imports: [
    CommonModule,
    InputModule,
    SelectModule,
    SchemeBlockModule,
    CounterModule,
    LoaderModule
  ],
  exports: [BuyBlockComponent, CommonModule]
})
export class BuyBlockModule { }
