import { Injectable } from '@angular/core';


@Injectable({ providedIn: 'root' })
export class StaticJsonDataService {
    static readonly JSON_DATA_DIR_URL: string = 'assets/json';
    static readonly ERROR_MESSAGE: string =
        'Something went wrong during static json data fetch: ';

    async fetchJsonData(jsonDataUrl: string): Promise<any[]> {
        try {
            const response = await fetch(jsonDataUrl);
            const jsonData = await response.json();
            return jsonData;
        } catch (error) {
            return await this.defaultErrorHandler(error, jsonDataUrl);
        }
    }

    private defaultErrorHandler(error: any, jsonDataUrl: string): Promise<any> {
        console.warn(StaticJsonDataService.ERROR_MESSAGE, error, jsonDataUrl);
        return Promise.reject(error);
    }
}