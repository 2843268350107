<footer class="layout__footer footer">
  <div class="container">
    <div class="row">
      <div class="section__main">
        <a (click)="clicknav('about')">
          <div class="wysiwyg">О событии</div>
        </a>
      </div>
      <div class="section__main">
        <a (click)="clicknav('organiser')">
          <div class="wysiwyg">Организатор мероприятия </div>
        </a>
      </div>
      <div class="section__main">
        <a (click)="clicknav('help')">
          <div class="wysiwyg">Нужна помощь?</div>
        </a>
      </div>
    </div>
  </div><!-- //container -->
</footer><!-- //footer -->
