import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { IEventItem } from '../../exports/interfaces/IEventItem';
import { IPromotionItem } from '../../exports/interfaces/IPromotionItem';
import { IDateItem } from '../../exports/interfaces/IDateItem';
import { EventService } from '../../services/event.service';
import { ActivatedRoute } from '@angular/router';
import { MONTHS } from '../../exports/constans';
import { formatDate } from '@angular/common';
import { IHttpResponse } from '../../exports/interfaces/IHttpResponse';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.css']
})
export class EventDetailComponent implements OnInit {
  item: IEventItem;
  eventId: number;
  promotion: IPromotionItem;
  selectedDate: IDateItem;
  enabled: boolean = true;
  format = 'dd.MM.yyyy';
  locale = 'en-US';
  time = 'dd.MM.yyyy HH:mm';
  selectedMonth: string;
  filteredDates: IDateItem[] = [];
  isLoading: boolean = true;
  MONTHS: any = [
    'Января',
    'Февраля',
    'Марта',
    'Апреля',
    'Мая',
    'Июня',
    'Июля',
    'Августа',
    'Сентября',
    'Октября',
    'Ноября',
    'Декабря'
  ];
  WEEKDAYS: any = [
    'понедельник',
    'вторник',
    'среда',
    'четверг',
    'пятница',
    'суббота',
    'воскресенье',
  ];
  months: any = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь'
  ];
  availableMonths: string[] = [];
  isDropdownOpen: boolean = false;

  constructor(
    private router$: ActivatedRoute,
    private event$: EventService,
    private cd: ChangeDetectorRef,
    private el: ElementRef,
  ) {
    this.router$.params.subscribe(params => {
      this.eventId = +params['event_id'];
    });
    this.item = this.event$.getEventData();
    if (this.item) {
      this.item = this.event$.getEventData();
      this.eventId = this.item.id;
    }
    this.event$.getDetail(this.eventId).subscribe(
      (response: IHttpResponse) => {
        this.item = response.results;
        this.event$.setEventData(this.item);
        if (this.item && this.item.dates.length > 0) {
          this.setEarliestDate();

          this.event$.promotions.subscribe(promotions => {
            if (promotions.length > 0) {
              this.promotion = promotions[0];
            }

            this.availableMonths = this.getAvailableMonths();
            if (this.availableMonths.length > 0) {
              this.selectedMonth = MONTHS[new Date(this.selectedDate.start_date).getMonth()];
              this.filterDatesByMonth(this.selectedMonth);
            }
          });
          this.event$.getPromotions({ event: this.item.id });
          this.isLoading = false;
        }
      },
      (error: HttpErrorResponse) => {
        if (error.status === 404) {
        } else if (error.status === 500) {
          console.log(error.message);
        }
        this.isLoading = false;
      }
    );


  }

  ngOnInit(): void {

  }

  scrollToDateSection() {
    const dateSectionElement = this.el.nativeElement.querySelector('.modal__title.title');
    if (dateSectionElement) {
      const elementRect = dateSectionElement.getBoundingClientRect();
      const offsetTop = elementRect.top + window.scrollY;
      const windowHeight = window.innerHeight;
      const scrollTo = offsetTop - (windowHeight / 3);

      window.scrollTo({
        top: scrollTo,
        behavior: 'smooth'
      });
    }
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  selectMonth(month: string) {
    this.selectedMonth = month;
    this.isDropdownOpen = false;
    this.filterDatesByMonth(month);
  }

  filterDatesByMonth(month: string) {
    this.filteredDates = this.item.dates.filter(date => {
      const dateMonth = new Date(date.start_date).getMonth();
      return MONTHS[dateMonth] === month;
    }).sort((a, b) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime());
  }

  getAvailableMonths(): string[] {
    const sortedDates = this.item.dates.sort((a, b) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime());
    const availableMonths = new Array<string>();
    sortedDates.forEach(date => {
      const dateMonth = MONTHS[new Date(date.start_date).getMonth()];
      if (!availableMonths.includes(dateMonth)) {
        availableMonths.push(dateMonth);
      }
    });

    return Array.from(availableMonths);
  }

  getPromotionName() {
    if (this.promotion) {
      if (this.promotion.type == "TEMPLATE") {
        if (this.promotion.template == "TWONE")
          return "2 + 1";
        else
          return "3 + 1";
      }
    }

    return "";
  }

  getPromotionDescription() {
    if (this.promotion) {
      if (this.promotion.type == "TEMPLATE") {
        return this.promotion.template_descr;
      } else if (this.promotion.type == "DYNAMIC" && this.promotion.dynamic_sum && this.promotion.dynamic_count > 0) {
        return `При покупке от ${this.promotion.dynamic_count} билетов - скидка ${this.promotion.dynamic_sum} ${this.promotion.dynamic_sum.indexOf("%") != -1 ? 'р.' : ''}`;
      } else if (this.promotion.type == "FIX" && this.promotion.fix_sum && this.promotion.dynamic_count > 0) {
        return `При покупке от ${this.promotion.dynamic_count} билетов - скидка ${this.promotion.fix_sum} ${this.promotion.fix_sum.indexOf("%") != -1 ? 'р.' : ''}`;
      }
    }

    return '';
  }

  getGallery() {
    var gall: any = [];
    if (this.item.stage_image.length > 0)
      gall = gall.concat(this.item.stage_image);
    gall = gall.concat(this.item.area.gallery);
    return gall;
  }

  getLatitude() {
    if (Array.isArray(this.item.area.location.coordinates)) {
      return parseFloat(this.item.area.location.coordinates[1]);
    }
    return 0;
  }

  getLongtitude() {
    if (Array.isArray(this.item.area.location.coordinates)) {
      return parseFloat(this.item.area.location.coordinates[0]);
    }
    return 0;
  }

  isDateSame() {
    return formatDate(this.item.start_date, "dd.MM.yyyy", "en-US") == formatDate(this.item.finish_date, "dd.MM.yyyy", "en-US");
  }

  setHtml() {
    const html: any = this.item.description;
    return html.replace(/\r?\n/g, '<br />');
  }

  showBuyBlock(date: IDateItem) {
    this.enabled = false;
    this.selectedDate = date;
    this.cd.detectChanges();
    this.enabled = true;
  }



  toDateTime(date: any) {
    return formatDate(date, this.time, this.locale);
  }

  setEarliestDate() {
    let earliestDate = this.item.dates[0];
    for (const date of this.item.dates) {
      if (new Date(date.start_date) < new Date(earliestDate.start_date)) {
        earliestDate = date;
      }
    }
    this.selectedDate = earliestDate;
  }

  getDate(date: IDateItem) {
    const cdate = new Date(date.start_date);
    return `${cdate.getDate()} ${this.MONTHS[cdate.getMonth()]} ${cdate.getUTCFullYear()}, ${this.WEEKDAYS[cdate.getDay()]}`;
  }

  getTime(date: IDateItem) {
    const time = new Date(date.start_date);
    return time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  }

}