<div>
  <div class="layout__empty" *ngIf="
      !isLoading &&
      (!item || item.status.code != 'active')
    ">
    <div data-app-image class="media__img" src="/assets/img/content/event/not_active.svg"></div>
    <div class="event-info">Мероприятие неактивно или перемещено</div>
  </div>
  <div class="layout__empty" *ngIf="
  !isLoading &&
  (item && item.dates.length <= 0 && item.status.code == 'active')
">
    <div data-app-image class="media__img" src="/assets/img/content/event/passed.svg"></div>
    <div class="event-info">Мероприятие прошло</div>
  </div>
  <div class="main__loading__frame" *ngIf="isLoading">
    <img src="/assets/img/content/loader/loader-spin.svg" alt="" />
  </div>
  <div class="layout__main" *ngIf="
      !isLoading &&
      item &&
      item.dates.length > 0 &&
      item.status.code == 'active'
    ">
    <!-- partial:parts/_breadcrumbs.html -->
    <div class="group__main">
      <div class="group__media media">
        <div data-app-image class="media__img" [src]="item.preview.image.small"></div>
      </div>
      <div class="group__content">
        <div class="group__title title">
          <h3 class="title__text">
            {{ item.name }}
          </h3>
        </div>
        <div class="group__type">
          <span class="type__text">{{ item.type.name }}</span>
          <span class="age__text">{{ item.age.name }}</span>
        </div>
        <div class="area__info">
          <div class="wysiwyg">
            <p>
              {{ item.area.name }}, {{ item.area.city.name }},
              {{ item.area.address }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="buy-section" *ngIf="item.start_date != 0 && item.available > 0">
      <div class="buy__header">
        <div class="modal__title title">
          <span class="title__text h3" *ngIf="item.dates.length > 1">Выбор на дату {{getDate(selectedDate)}},
            {{getTime(selectedDate)}}</span>
        </div>
        <div>
          <a class="nav__link" routerLink="/basket">Корзина</a>
        </div>
      </div>
      <div class="date-container" *ngIf="item.dates.length <= 1">
        <span class="title__text h3">Покупка билета {{getDate(item.dates[0])}}, {{getTime(item.dates[0])}}</span>
      </div>
      <div class="date-container" *ngIf="item.dates.length > 1">
        <div class="month-picker">
          <div class="custom-dropdown" [ngClass]="{ open: isDropdownOpen }">
            <div class="custom-dropdown-toggle" (click)="toggleDropdown()">
              {{ selectedMonth }}
              <svg class="media__ico">
                <use xlink:href="/assets/img/sprite.svg#arrowBottom"></use>
              </svg>
            </div>
            <ul class="custom-dropdown-menu">
              <li *ngFor="let month of availableMonths" (click)="selectMonth(month)">
                {{ month }}
              </li>
            </ul>
          </div>
        </div>
        <div class="date-buttons">
          <button class="action__btn btn modal-init date-button" [ngClass]="{ btn_active: date == selectedDate }"
            *ngFor="let date of filteredDates" (click)="showBuyBlock(date)">
            {{ toDateTime(date.start_date) }}
          </button>
        </div>
      </div>
      <div class="wrap-buy-item" *ngIf="selectedDate && enabled" app-buy-block [event]="item" [date]="selectedDate">
      </div>
    </div>
  </div>
  <div data-app-footer [event]="item"></div>
</div>
