<div>
  <main class="layout__main">
    <!-- СЕКЦИЯ КОРЗИНЫ -->
    <div class="layout__section section">
      <div class="group__main" *ngIf="event">
        <div class="group__media media">
          <div data-app-image class="media__img" [src]="event.preview.image.small"></div>
        </div>
        <div class="group__content">
          <div class="group__title title">
            <h3 class="title__text">
              {{ event.name }}
            </h3>
          </div>
          <div class="group__type">
            <span class="type__text">{{ event.type.name }}</span>
            <span class="age__text">{{ event.age.name }}</span>
          </div>
          <div class="area__info">
            <div class="wysiwyg">
              <p>{{ event.area.name}}, {{ event.area.city.name}}, {{ event.area.address }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="section__header">
        <div class="buy__header">
          <div class="modal__title title">
            <span class="title__text h3">Корзина</span>
          </div>
        </div>
      </div>
      <div data-app-basket-wrap></div>
    </div>
  </main>
  <div *ngIf="event" data-app-footer [event]="event"></div>
</div>
