import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RandomStringFunction } from 'src/app/exports/functions/RandomStringFunction';
import { IModalTypeParams } from 'src/app/exports/interfaces/IModalTypeParams';
import { ModalType } from 'src/app/exports/types/ModalType';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'div[data-app-modal-notification]',
  templateUrl: './modal-notification.component.html',
  styleUrls: ['./modal-notification.component.css']
})
export class ModalNotificationComponent implements OnInit {

  @Output() data = new EventEmitter<ModalType>();
  @Input() params: IModalTypeParams;
  title: string = '';
  id: any = false;
  modalText: any = 'notification-tiny';

  constructor(
    private modal$: ModalService
  ) { }

  ngOnInit(): void {
    if (this.params.type == this.modalText) {
      const aboutTitle: string = 'О событии';
      const orgTitle: string = 'Организатор мероприятия';
      const helpTitle: string = 'Нужна помощь?';

      let title = false;

      if (this.params.params.id)
        this.id = this.params.params.id;

      if (this.params.params.title) {
        title = true;
      }

      if (this.params.params.type == 'about') {
        if (title)
          this.title = this.params.params.title;
        else
          this.title = aboutTitle;
      }

      if (this.params.params.type == 'organiser') {
        if (title)
          this.title = this.params.params.title;
        else
          this.title = orgTitle;
      }

      if (this.params.params.type == 'help') {
        if (title)
          this.title = this.params.params.title;
        else
          this.title = helpTitle;
      }
    }
  }

  close() {
    this.data.emit(this.modalText);
  }

  clicking() {
    this.close();
    if (this.id) {
      this.modal$.buttonClick.next(this.id);
    }
  }

}
