import { Component, OnInit } from '@angular/core';
import { IEventItem } from 'src/app/exports/interfaces/IEventItem';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {
  event: IEventItem;

  constructor(
    private event$: EventService
  ) {
    this.event = this.event$.getEventData();
  }

  ngOnInit(): void {
  }

}
