import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ErrorHandlerClass } from 'src/app/exports/classes/ErrorHandlerClass';
import { PHONE_REG_EXP } from 'src/app/exports/constans';
import { IBasketItem } from 'src/app/exports/interfaces/IBasketItem';
import { IHttpResponse } from 'src/app/exports/interfaces/IHttpResponse';
import { CurrentUserType } from 'src/app/exports/types/CurrentUserType';
import { AccountService } from 'src/app/services/account.service';
import { BaoService } from 'src/app/services/bao.service';
import { ModalService } from 'src/app/services/modal.service';
import { UserService } from 'src/app/services/user.service';
import { PassConfValidation } from '../../validations/passconfvalidation';
import { RandomStringFunction } from 'src/app/exports/functions/RandomStringFunction';
import bridge, { UserInfo } from '@vkontakte/vk-bridge';
import { IUserItem } from 'src/app/exports/interfaces/IUserItem';
import { IVKUser } from 'src/app/exports/interfaces/IVKUser';
import { EventService } from 'src/app/services/event.service';
import { IEventItem } from 'src/app/exports/interfaces/IEventItem';

type CheckoutType = "phys" | "legal";

@Component({
  selector: 'div[data-app-checkout-wrap]',
  templateUrl: './checkout-wrap.component.html',
  styleUrls: ['./checkout-wrap.component.css']
})
export class CheckoutWrapComponent implements OnInit {

  auth: boolean = false;
  items: IBasketItem[] = [];
  user: CurrentUserType = false;
  type: CheckoutType = "phys";
  payment: number;
  errors: Subject<any> = new Subject();
  defaultPsyhValue = new Subject();
  vkuser: IVKUser;
  earliestBasket: IBasketItem | null = null;
  timeTo: number | null = 0;
  countdownInterval: any;
  event: IEventItem;
  formattedTime: string | null = null;

  formSubmit: Subject<any> = new Subject();

  physicForm = new FormGroup(
    {
      login: new FormControl(''),
      username: new FormControl<string>('', [
        Validators.minLength(4),
        Validators.required
      ]),
      phone: new FormControl<string>('', [
        Validators.pattern(PHONE_REG_EXP),
        Validators.required
      ]),
      surname: new FormControl<string>('', [
        Validators.minLength(4),
      ]),
      email: new FormControl<string>('', [
        Validators.minLength(4),
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')
      ]),
      password: new FormControl<string>(''),
      confirmPassword: new FormControl<string>(''),
      abilet: new FormControl<boolean>(false),
    },
    [PassConfValidation.MatchValidator('password', 'confirmPassword')]
  );


  constructor(
    private user$: UserService,
    private bao$: BaoService,
    private account$: AccountService,
    private router$: Router,
    private modal$: ModalService,
    private event$: EventService
  ) {
    this.event = this.event$.getEventData();
  }

  setPayment(id: number) {
    this.payment = id;
  }

  submit() {
    this.bao$.response.subscribe((response: IHttpResponse) => {
      //const response: any = {"results":{"orderId":"7ea75069-0070-7159-be67-87852908a320","url":"https://secure-payment-gateway.ru/payment/merchants/sbersafe_sberid/payment_ru.html?mdOrder=7ea75069-0070-7159-be67-87852908a320"}}
      if (response.results === true) {
        this.modal$.open('notification-tiny', {
          type: 'success',
          text: "Заказ успешно оформлен"
        });
        this.router$.navigate(["/event"]);
      } else if (typeof response.results == "object") {
        window.open(
          response.results.url,
          '_blank'
        );

        const id$: any = RandomStringFunction();

        this.modal$.buttonClick.subscribe((i: any) => {
          if (i == id$) {
            window.open(
              response.results.url,
              '_blank'
            );
          }
        });

        this.modal$.open('notification-tiny', {
          id: id$,
          type: 'success',
          text: 'Заказ успешно оформлен',
          buttonText: 'Повторить оплату'
        });

      }
      localStorage.setItem('discount', '');
    });


    const params: any = {
      payment: this.payment
    };

    if (this.type == 'phys') {
      if (this.physicForm.controls.email) {
        params.email = this.physicForm.controls.email.value;
      }
      if (this.physicForm.controls.phone) {
        params.phone = this.physicForm.controls.phone.value;
      }
    }

    const discount = this.bao$.getLocalDiscount();

    if (discount && typeof discount == "object" && discount.hasOwnProperty('id'))
      params.discount = discount.id;

    this.bao$.checkOut(params);
  }

  process(data: any) {
    data.login = data.email;

    if (!this.auth) {

      data.f_user = this.bao$.getFUserKey();

      this.account$.authResponse.subscribe((res: IHttpResponse) => {
        if (res.results.hasOwnProperty('token')) {
          console.log('token exists for ', res.results, ' trying to submit()');
          this.submit();
        }
        else { console.log('no token found for ', res.results); }
      });
      this.account$.registerResponse.subscribe((response: IHttpResponse) => {
        console.log('regResp:', response);
        if (response.results) {
          data.login = data.email;
          this.account$.auth({
            login: data.login,
            password: data.password
          });
        }
      });


      if (data.abilet) {
        this.account$.register(data);

      }

    }

    if (this.auth) {
      if (typeof this.user == 'object' && 'id' in this.user) {
        this.account$.updateUser(this.user.id, data);
        this.submit();
      }
    }

    if (!data.abilet) {
      this.submit();
    }
  }

  physicFormSubmit(event: any) {
    this.formSubmit.next({});
    this.errors.next({});

    if (this.auth || !this.physicForm.controls.abilet.value) {
      this.physicForm.controls.password.setErrors(null);
      this.physicForm.controls.confirmPassword.setErrors(null);
    }

    if (!ErrorHandlerClass.AnyErrors(this.physicForm)) {
      const data = this.physicForm.value;
      this.account$.getErrors().subscribe(errors => {
        if (errors.hasOwnProperty('error'))
          this.errors.next(errors.error);
      });
      this.process(data);
    }
  }


  ngOnInit(): void {
    this.fetchUserData().then((data: IVKUser) => {
      this.vkuser = data;
      setTimeout(() => {
        this.defaultPsyhValue.next(this.vkuser);
      }, 300);
      this.validatePassword();
    })
      .catch((error) => {
        console.error('Error fetching user data: ', error);
      });

    this.bao$.items.subscribe((items: IBasketItem[]) => {
      if (items.length == 0)
        this.router$.navigate(["/event"]);
      this.items = items;
      this.updateEarliestBasket();
    });
    this.bao$.getCurrentBasket();
  }

  changeType(type: CheckoutType) {
    this.type = type;
  }

  haveAccount(event: any) {
    event.preventDefault();
    localStorage.setItem('previousRoute', '/checkout');
    this.router$.navigate(["/auth"]);
  }

  getAuth() {
    return !!this.auth;
  }

  //nikita
  togglePhysicAbilet(event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;
    this.physicForm.controls.abilet.setValue(isChecked);
  }


  validatePassword() {
    //const abiletControl = control.parent?.get('abilet')
    const abiletControl = this.physicForm.controls.abilet;
    abiletControl.valueChanges.subscribe(abiletControl => {
      if (abiletControl) {
        this.physicForm.controls.password.setValidators(Validators.required);
        this.physicForm.controls.confirmPassword.setValidators(Validators.required);
      }
      else {
        this.physicForm.controls.password.setValidators(null);
        this.physicForm.controls.confirmPassword.setValidators(null);
      }
      this.physicForm.controls.password.updateValueAndValidity();
      this.physicForm.controls.confirmPassword.updateValueAndValidity();
    });
  }

  async fetchUserData() {
    const user: UserInfo = await bridge.send('VKWebAppGetUserInfo');
    const email = await bridge.send('VKWebAppGetEmail');
    const phone = await bridge.send('VKWebAppGetPhoneNumber');
    let data: IVKUser = {
      email: email.email,
      phone: phone.phone_number,
      surname: user.last_name,
      username: user.first_name
    };
    return data;

  }

  updateEarliestBasket(): void {
    if (this.items.length > 0) {
      const sortedItems = this.items.slice().sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
      this.earliestBasket = sortedItems[0];
      if (this.earliestBasket) {
        this.timeTo = 900 - this.earliestBasket.lifetime;
        this.startCountdown();
      }
    } else {
      this.earliestBasket = null;
      this.timeTo = null;
      this.formattedTime = null;
    }
  }

  startCountdown(): void {
    if (this.timeTo) {
      this.countdownInterval = setInterval(() => {
        if (this.timeTo) {
          this.timeTo--;
          this.formatTime();
          if (this.timeTo <= 0) {
            clearInterval(this.countdownInterval);
            if (this.earliestBasket && this.earliestBasket.lifetime) {
              this.removeProduct(this.earliestBasket);
              this.updateEarliestBasket();
            }
          }
        }
      }, 1000);
    }
  }

  formatTime(): void {
    if (this.timeTo && this.timeTo >= 0) {
      const minutes = Math.floor(this.timeTo / 60);
      const seconds = this.timeTo % 60;
      this.formattedTime = `${this.padZero(minutes)}:${this.padZero(seconds)}`;
    }
  }

  padZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  removeProduct(item: IBasketItem) {
    this.bao$.removeProduct(item.id);
    this.bao$.response.subscribe((response: IHttpResponse) => {
      if (response.results)
        this.bao$.getCurrentBasket();
    });
  }
}