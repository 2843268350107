<div class="layout__modal" *ngIf="types.length > 0" (click)="clickOutForm($event)">
  <div *ngFor="let val of types | keyvalue: returnZero">
    <div class="modal__main modal__layout modal__layout_active" *ngIf="toNum(val.key) == 0"
      [ngClass]="{modal__layout_tiny: isTiny(val.value)}">
      <div class="modal-wrap-item" [ngStyle]="{zIndex: val.key}" (data)="close($event)" [params]="getParams(val.key)"
        data-app-modal-text *ngIf="val.value == 'text'"></div>
      <div class="modal-wrap-item" [ngStyle]="{zIndex: val.key}" (data)="close($event)" [params]="getParams(val.key)"
        data-app-modal-notification *ngIf="val.value == 'notification-tiny'"></div>
    </div>
  </div>
</div>
