import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from './app-routing.module';
import { InputModule } from './components/input/input.module';
import { AppComponent } from './app.component';
import { BuyBlockModule } from './components/public/buy-block/buy-block.module';
import { EventDetailComponent } from './pages/event-detail/event-detail.component';
import { GlobalMessageComponent } from './components/global-message/global-message.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FavoriteComponent } from './components/public/favorite/favorite.component';
import { CheckoutWrapModule } from './components/public/checkout-wrap/checkout-wrap.module';
import { BasketWrapModule } from './components/public/basket-wrap/basket-wrap.module';
import { InputDateModule } from './components/input-date/input-date.module';
import { InputPhoneModule } from './components/input-phone/input-phone.module';
import { SelectModule } from './components/select/select.module';
import { InputCheckboxModule } from './components/input-checkbox/input-checkbox.module';
import { InputTimeModule } from './components/input-time/input-time.module';
import { BasketDiscountModule } from './components/public/basket-wrap/children/basket-sum-block/children/basket-discount/basket-discount.module';
import { BasketComponent } from './pages/basket/basket.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { FooterModule } from './components/public/footer/footer.module';
import { ImageModule } from './components/image/image.module';
import { HomeComponent } from './pages/home/home.component';
import { ModalModule } from './components/modal/modal.module';


@NgModule({
  declarations: [
    AppComponent,
    EventDetailComponent,
    AppComponent,
    GlobalMessageComponent,
    EventDetailComponent,
    FavoriteComponent,
    BasketComponent,
    CheckoutComponent,
    HomeComponent,
  ],
  imports: [
    InputModule,
    RouterModule,
    InputCheckboxModule,
    BuyBlockModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    InputModule,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ModalModule,
    BuyBlockModule,
    BasketWrapModule,
    BasketDiscountModule,
    CheckoutWrapModule,
    InputPhoneModule,
    ReactiveFormsModule,
    InputDateModule,
    SelectModule,
    InputTimeModule,
    FooterModule,
    ImageModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
