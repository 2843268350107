import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  constructor(
    private router$: Router,
  ) {
    const currentUrl = window.location.hash;
    let eventId = parseInt(currentUrl.slice(1), 10);
    if (eventId) { this.router$.navigate(['/event', { "event_id": eventId }]); }
  }
}
