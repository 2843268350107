<div class="basket__group group" *ngIf="product">
    <div class="group__main basket_table">
        <div class="group__content">
            <div class="group__second">
                <table class="table_basket">
                    <tbody>
                    <tr *ngFor="let item of group.items">
                        <td>
                            <div class="data__item">
                                <span class="data__text">{{ item.date.start_date | date:"dd.MM.Y" }} {{ item.date.start_date | date:"HH:mm" }}</span>
                            </div>
                        </td>
                        <td [innerHTML]="getName(item)"></td>
                        <td>
                            <div class="form-item">
                                <div class="form-item__main">
                                    <div class="form-item__basket">
                                        <div data-app-counter
                                            (data)="setCount(item, $event)"
                                            [value]="item.quantity"
                                            *ngIf="item.category"
                                            [max_length]="item.available"
                                            [min_length]="1"
                                        ></div>
                                    </div>
                                </div>
                            </div><!-- //form-item -->
                        </td>
                        <td [innerHTML]="getPrice(item)"></td>
                        <td>
                            <div class="group__action action" (click)="removeProduct(item)">
                                <svg class="action__ico" style="height:20px">
                                    <use xlink:href="/assets/img/sprite.svg#basket"></use>
                                </svg>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
