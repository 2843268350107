import { Component, Input, OnInit } from '@angular/core';
import { IEventItem } from 'src/app/exports/interfaces/IEventItem';
import { IHttpResponse } from 'src/app/exports/interfaces/IHttpResponse';
import { IJsonItem } from 'src/app/exports/interfaces/IJsonItem';
import { IPageItem } from 'src/app/exports/interfaces/IPageItem';
import { ModalService } from 'src/app/services/modal.service';
import { PageService } from 'src/app/services/page.service';
import { StaticJsonDataService } from 'src/app/services/staticjsondata.service';

@Component({
  selector: 'div[data-app-footer]',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  @Input() event: IEventItem;
  item: IPageItem;
  contentValue: IJsonItem;
  isFooterActive = false;

  constructor(
    private page$: PageService,
    private staticJsonDataService: StaticJsonDataService,
    private modal$: ModalService) {

  }

  ngOnInit(): void {
    const jsonDataUrl: string = `${StaticJsonDataService.JSON_DATA_DIR_URL}/contact-info.json`;
    this.staticJsonDataService.fetchJsonData(jsonDataUrl).then((jsonData) => {
      this.contentValue = jsonData[1];
    });
    this.page$.getPage('footer').subscribe((response: IHttpResponse) => {
      if (response.results)
        this.item = response.results;
    });
  }

  clicknav(nav: string) {
    let text: string | null = ``;
    switch (nav) {
      case ('about'):
        text = this.event.description;
        break;
      case ('organiser'):
        text = `${this.event.user.organisation_name}, ИНН ${this.event.user.inn}, ОГРН ${this.event.user.ogrn}`;
        break;
      case ('help'):
        text = ``;
        break;
    }
    this.modal$.open('notification-tiny', {
      type: nav,
      text: text
    });
  }


}
