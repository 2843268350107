import { Component, Input, OnInit } from '@angular/core';
import { IBasketItem } from 'src/app/exports/interfaces/IBasketItem';
import { IDiscountItem } from 'src/app/exports/interfaces/IDiscountItem';
import { IEventItem } from 'src/app/exports/interfaces/IEventItem';
import { IGroupedItem } from 'src/app/exports/interfaces/IGroupedItem';
import { IHttpResponse } from 'src/app/exports/interfaces/IHttpResponse';
import { BaoService } from 'src/app/services/bao.service';

@Component({
  selector: '[data-app-basket-item]',
  templateUrl: './basket-item.component.html',
  styleUrls: ['./basket-item.component.css']
})
export class BasketItemComponent implements OnInit {

  @Input() group: IGroupedItem;
  manager: any;
  discount: boolean = false;
  init: boolean = false;
  product: IEventItem;

  constructor(
    private basket$: BaoService
  ) {
  }

  getTypes() {
    return this.product.type.name;
  }

  ngOnInit(): void {
    this.product = this.group.items[0].product;
  }

  getPrice(item: IBasketItem) {
    let price = '';
    if (item.discount_price > 0) {
      price += `<p class="discount"><strong>${item.pricing} р.</strong></p>`;
      price += `<p><strong>${item.discount_price} р.</strong></p>`;
    } else
      price += `<p><strong>${item.pricing} р.</strong></p>`;

    return price;
  }



  totalPrice() {
    let totalPrice = 0;
    const discount: IDiscountItem = this.basket$.getLocalDiscount();

    this.group.items.forEach((item: IBasketItem) => {
      if (discount && item.discount_price > 0) {
        let pr = 0;

        if (discount.point == 'percent')
          pr = item.discount_price * item.quantity;
        else
          pr = item.pricing * item.quantity - (item.pricing - item.discount_price);

        totalPrice += pr;
      } else {
        totalPrice += item.pricing * item.quantity;
      }
    });

    return totalPrice;
  }

  getTotalQuantity(): number {
    let totalQuantity = 0;

    this.group.items.forEach((item: IBasketItem) => {
      totalQuantity += item.quantity;
    });

    return totalQuantity;
  }

  setCount(item: IBasketItem, quantity: number) {
    const f_user = this.basket$.getFUserKey();

    const params: any = {
      product: item.product.id,
      date: item.date.id,
      quantity,
      f_user
    };

    if (item.place)
      params.place = item.place.id;

    if (item.category)
      params.category = item.category.id;

    if (item.price)
      params.price = item.price.id;

    if (item.quantity != quantity) {
      this.basket$.addBasket(params).subscribe(res => {
        this.basket$.getCurrentBasket();
      });
    }

  }

  getName(item: IBasketItem) {
    let name: any = '';
    if (item.category && item.category.name) {
      name += `<p><strong>${item.category.name}</strong></p>`;
      if (item.category.description)
        name += `<p>${item.category.description}</p>`;
    } else if (item.price && item.place) {
      name += `<p><strong>${item.place.name} место</p><p>${item.place.row}</strong></p>`;
    }

    return name;
  }

  removeProduct(item: IBasketItem) {
    this.basket$.removeProduct(item.id);
    this.basket$.response.subscribe((response: IHttpResponse) => {
      if (response.results)
        this.basket$.getCurrentBasket();
    });

  }

}
