import { Component, OnInit } from '@angular/core';
import { IBasketItem } from 'src/app/exports/interfaces/IBasketItem';
import { IGroupedItem } from 'src/app/exports/interfaces/IGroupedItem';
import { IHttpResponse } from 'src/app/exports/interfaces/IHttpResponse';
import { BaoService } from 'src/app/services/bao.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'div[data-app-basket-wrap]',
  templateUrl: './basket-wrap.component.html',
  styleUrls: ['./basket-wrap.component.css']
})


export class BasketWrapComponent implements OnInit {

  items: Array<IBasketItem> = [];
  groupedItems: IGroupedItem[] = [];
  earliestBasket: IBasketItem | null = null;
  timeTo: number | null = 0;
  countdownInterval: any;
  formattedTime: string | null = null;

  constructor(
    private basket$: BaoService,
    private user$: UserService
  ) {
    this.basket$.getCurrentBasket();

    this.basket$.items.subscribe((items: IBasketItem[]) => {
      this.items = items;
      this.groupedItems = this.groupBasketItemsByProduct(this.items);
      this.updateEarliestBasket();
    });
  }

  groupBasketItemsByProduct(items: IBasketItem[]): IGroupedItem[] {
    const groupedItems: IGroupedItem[] = [];
    items.forEach(item => {
      const existingGroup = groupedItems.find(group => group.product === item.product.id);
      if (existingGroup) {
        existingGroup.items.push(item);
      } else {
        groupedItems.push({
          product: item.product.id,
          items: [item]
        });
      }
    });
    return groupedItems;
  }

  ngOnInit(): void { }

  updateEarliestBasket(): void {
    if (this.items.length > 0) {
      const sortedItems = this.items.slice().sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
      this.earliestBasket = sortedItems[0];
      if (this.earliestBasket) {
        this.timeTo = 900 - this.earliestBasket.lifetime;
        this.startCountdown();
      }
    } else {
      this.earliestBasket = null;
      this.timeTo = null;
      this.formattedTime = null;
    }
  }

  startCountdown(): void {
    if (this.timeTo) {
      this.countdownInterval = setInterval(() => {
        if (this.timeTo) {
          this.timeTo--;
          this.formatTime();
          if (this.timeTo <= 0) {
            clearInterval(this.countdownInterval);
            if (this.earliestBasket && this.earliestBasket.lifetime) {
              this.removeProduct(this.earliestBasket);
              this.updateEarliestBasket();
            }
          }
        }
      }, 1000);
    }
  }

  formatTime(): void {
    if (this.timeTo && this.timeTo >= 0) {
      const minutes = Math.floor(this.timeTo / 60);
      const seconds = this.timeTo % 60;
      this.formattedTime = `${this.padZero(minutes)}:${this.padZero(seconds)}`;
    }
  }

  padZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  removeProduct(item: IBasketItem) {
    this.basket$.removeProduct(item.id);
    this.basket$.response.subscribe((response: IHttpResponse) => {
      if (response.results)
        this.basket$.getCurrentBasket();
    });
  }

}
