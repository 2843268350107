
<div class="frame__main">
    <div class="frame__header">
        <a class="logo" href="https://bilet-tut.ru">
            <img class="logo__img" src = "/assets/img/logo.svg" alt="My Happy SVG"/>
        </a>
        <div class="header__text">
            <p>Ваша персональная система дистрибуции и продажи билетов на мероприятия</p>
        </div>
    </div>
    <div class="frame__block">
        <p class="block__text">
            Регистрация участников событий, бронирование и продажа билетов на концерты, фестивали, мастер-классы, спортивные и другие мероприятия. Продукт для организаторов, концертных агентств, промоутеров и артистов (доступно юридическим и физическим лицам).
        </p>
        <p class="block__text">Получите аккаунт на <a href="https://bilet-tut.ru/" target="_blank">bilet-tut.ru</a>, создайте мероприятие в личном кабинете, настройте приложение и запустите продажи</p>
    </div>
</div>
