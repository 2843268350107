import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './modal.component';
import { ModalTextModule } from './children/modal-text/modal-text.module';
import { ModalNotificationModule } from './children/modal-notification/modal-notification.module';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [ModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    ModalTextModule,
    ModalNotificationModule
  ],
  exports: [ModalComponent, CommonModule]
})
export class ModalModule { }
