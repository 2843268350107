import bridge from "@vkontakte/vk-bridge";
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';

bridge.send("VKWebAppInit");

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  title = 'Bilet';

  loading = false;

  constructor(
    private router: Router
  ) {
    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }

        case event instanceof NavigationEnd: {
          setTimeout(() => {
            this.loading = false;
          }, 500);
          window.scrollTo(0, 0);
          break;
        }
        case event instanceof NavigationCancel: {
          //console.log('NavigationCancel')
          break;
        }
        case event instanceof NavigationError: {
          this.loading = false;
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  ngOnInit() {

  }
}
