<div class="modal__header">
  <div class="modal__title title">
    <span class="title__text h3">{{ title }}</span>
  </div>
  <div class="modal__action action" (click)="close()">
    <svg class="action__icon">
      <use xlink:href="/assets/img/sprite.svg#close"></use>
    </svg>
  </div>
</div>
<!-- modal content -->
<div class="modal__content modal__main">
  <div class="modal-text modal-description" *ngIf="params.params.type == 'about'">
    <p *ngIf="params.params.text">
      {{ params.params.text }}
    </p>
  </div>
  <div class="modal-text" *ngIf="params.params.type == 'organiser'">
    <p *ngIf="params.params.text">
      {{ params.params.text }}
    </p>
  </div>
  <div class="modal-help" *ngIf="params.params.type == 'help'">
    <a href="https://bilet-tut.ru/account/return">
      <div class="help-text">Возврат билетов</div>
    </a>
    <a href="https://bilet-tut.ru/public-offert">
      <div class="help-text">Публичная оферта</div>
    </a>
    <a href="https://bilet-tut.ru/faq">
      <div class="help-text">Часто задаваемые вопросы</div>
    </a>
  </div>
  <br />
</div>

<div class="modal__footer" *ngIf="this.params.params.buttonText">
  <div class="row">
    <div class="col col_6">
      <button type="button" (click)="close()" class="btn btn_secondary modal__action">Отмена</button>
    </div>
    <div class="col col_6">
      <button type="button" (click)="clicking()" class="btn">{{ this.params.params.buttonText }}</button>
    </div>
  </div>
</div>
